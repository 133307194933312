// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-publish-jsx": () => import("./../src/pages/publish.jsx" /* webpackChunkName: "component---src-pages-publish-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */)
}

