import React from 'react'

const defaultState = {
  appicplay: {}
};
const AppicPlayContext = React.createContext(defaultState);
class AppicPlayProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      appicplay: Object.assign({}, window.$$$___call__back__json___$$$)
    }
  }


  // async componentWillMount() {
  //   this.setState({
  //     appicplay: Object.assign({}, window.$$$___call__back__json___$$$),
  //   })
  // }

  render() {
    const { children } = this.props;
    const { appicplay } = this.state;
    return (
      <AppicPlayContext.Provider value={{ appicplay }} >
        { children }
      </AppicPlayContext.Provider>
    )
  }
}

export default AppicPlayContext
export { AppicPlayProvider }
